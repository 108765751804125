.filter-container {
  position: fixed;
  z-index: 1;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(148, 142, 142, 0.59);
  box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  transition: width 0.2s ease-in-out;
  font-weight: 500;
  font-family: inherit;
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 1rem;
  cursor: pointer;
  bottom: 150px;
}

.filter-container:focus {
  outline: none;
}

.input-filter-txt {
  display: inline;
  opacity: 0;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  font: 17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: rgba(55, 55, 55, 0.919);
  font-weight: 600;
  background-color: transparent;
  width: 9%;
  border: none; 
  transition: opacity 0.2s;
}

.add-filter{
  position: fixed;
  left: 0 !important;
  top: 0 !important;
  margin-top: 88px !important;
  margin-left: 26rem !important;
}

.add-filter:hover {
  width: 150px;
}

.add-filter:hover::before,
.add-filter:hover::after {
  width: 4px;
  border-radius: 2px;
}

.add-filter:hover .input-filter-txt {
  opacity: 1;
}

.add-filter-cont{
  font-size: 25px;
  margin-top: 12px;
  margin-left: 12px;
  color: #05caca;
}

.add-filter-cont::after,
.add-filter-cont::before {
  transition: all 0.2s ease-in-out;
  content: "";
  position: absolute;
  height: 20px;
  width: 2px;
  top: calc(50% - 10px);
  background:  rgba(0, 255, 255, 0.719);
  overflow: hidden;
}

.add-filter-cont::before {
  left: 22px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.add-filter-cont::after {
  right: 22px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.add-filter:hover .add-filter-cont::before {
  left: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.add-filter:hover .add-filter-cont::after {
  right: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}