body{
  margin:0;
}

.container-login{
  position: relative;
  display: flex;
  width:100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgb(0,180,219);
  background: linear-gradient(16deg, rgba(0,180,219,1) 35%, rgba(0,131,176,1) 100%);
}

.card-login{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width:80%;
  height:70%;
  background-color:white;
  border-radius: 8px;
  overflow:hidden;
  -webkit-box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.52);
  -moz-box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.52);
  box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.52);
  
   
}
.containerImg{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,207,72,0.7819502801120448) 0%, rgba(33,186,217,0.9108018207282913) 26%);
}
.imgMain{
  resize: center;
  width: 100%;
  height: 30%;
}
.containerForm {
  width: 50%;
  height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  background-color: white;
}
.containerInput{
  width: 100%;
  height: 40px;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}
.textInput {
  width:80%;
  border-radius:5px; 
  border-width: 1px;
  padding: 10px;
  border-color: #00d9ffde;
}
.btnSubmitStyle{
  width:50%; 
  color: white;
  font-size: 20px;
  border:none;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(218,177,60,1) 0%, rgba(255,183,65,1) 26%);
  border-radius:15px;
  text-align: center;
  margin-top: 15px;
  padding: 10px 5px;
  cursor: pointer;
  box-shadow: 10px 10px 15px -6px rgba(0,0,0,0.52);
}
.btnSubmitStyle:hover{
  
}
.btnSubmitContainerStyle{
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
}
.containerG4{
  width:100%; 
  display: flex;
  justify-content: center; 
  flex-direction: row; 
  align-items: center;
  height: 50px; 
  position:absolute; 
  bottom: 5px;

}
.containerG4Text{
  color: #fff; 
  font-size: 12px;
}
.containerG4Img{
  width: 150px; 
  height: 100%; 
  resize: center;
}

