.container-requests-list{
    display: flex;
    flex-direction: column;/*
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;*/
    padding: 6px;
    height: 100%;
}

.container-r-list-item{
    list-style: none; 
    padding-top: 10px;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    border: 2px solid black;
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    margin-top: 10px;
    margin-right: 5px;
    text-align: center;
}

.container-r-list-item:hover{
    transform: scale(0.992);
}

.container-r-list-item-header{
    display: flex;/*
    justify-content: center;*/
    border-bottom:solid 1px grey;
    width: 100%;
    font-size: 35px;
}

.r-list-item-header-title{
    margin: 0 auto;
    margin-top: 10px;
    max-width: 750px;
}

.r-list-item-header-clave{
    font-weight: 600;
    margin-top: 10px;
    margin-left: 60px;
}

.r-list-item-header-priority-urgente{
    margin-bottom: 10px;
    padding: 7px;
    color: white;
    background-color: red;
    border-radius: 10px;
}
.r-list-item-header-priority-alta{
    margin-bottom: 10px;
    padding: 7px;
    color: white;
    background-color: orange;
    border-radius: 10px;
}
.r-list-item-header-priority-media{
    margin-bottom: 10px;
    padding: 7px;
    color: white;
    background-color: green;
    border-radius: 10px;
}


.container-r-list-item-details{
    display: flex;
    justify-content:space-between;
    width: 100%;
    font-size: 19px;
}

.r-list-item-details-title{
    margin-top: 10px;
    text-align: center;
}

.r-list-item-details-title-apl{
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    text-decoration: underline;
    color:#342ead;
    display: inline;
    font-size: 22px;
}

.r-list-item-details-title-apl-1:hover{
    cursor: pointer;
    color: #3d4d87;
    font-weight: bold;
}

.r-list-item-details-title-apl:hover{
    cursor: pointer;
    color: #3d4d87;
    font-weight: bold;
}

.r-list-item-details-clave{
    margin-left: 20px;
    margin-top: 10px;
    height: 60px;
    font-weight: 600;
    padding: 7px;
    border-radius: 15px;
    background-color: rgb(198, 198, 198);
}



.container-r-list-item-btn{
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.r-list-item-btn-A{
    margin: 0 auto;
    font-size: 38px;
    width: 100%;
    background-color: rgba(255, 159, 6, 0.699);
    border: 1px solid black;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: white;
}

.r-list-item-btn-A:hover{
    cursor: pointer;
    transform: scale(1.01);
}

.modalReq{
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
	justify-content: center;
	align-items: center;
}

.margin-right-r{
    margin-right: 20px;
}

.navbar-reqList {
    /* margin-top: 15px; */
    margin-top: 20px;
    border-top: 2px solid;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.navbar-container-reqList{
    display: flex;
	flex-grow: 1;
	height: 75px;
	width: 350px;
	overflow: hidden;
	box-shadow: 0 0 1px 0 rgba(#00c3ff, 0.25), 0 15px 30px 0 rgba(#342ead, 0.1);
}

.navbar-container-reqList-active{
    display: flex;
	flex-grow: 1;
	height: 75px;
	width: 350px;
	overflow: hidden;
    border-bottom:solid 3px rgb(22, 239, 247);
}

.navbar-item-reqList {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
    padding: 0 0 0 0;
	color: rgb(126, 126, 126);
	text-decoration: none;
	outline: none;
	border: none;
	background: transparent;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	cursor: pointer;
	transition: all .3s;
}

.navbar-item-reqList:hover{
	color: rgb(11, 190, 190);
 	outline: 0;
}

.container-resend{
    background-color: rgba(52, 223, 52, 0.781);
    font-size: 35px;
    color: white;
    border-top:solid 1px grey;
    cursor: pointer;
}

.relleno{
    width: 100%; 
    height: 100vh;
}

.navbar-container-disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }