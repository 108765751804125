ul {
    padding: 0;
}

.centerInput {
    
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 15px;
    box-shadow: 0 2px 7px 1px gray;
    border-radius: 5px;
}

.codeCamp {
    background-color: rgb(194, 192, 192);
    width: 30%;
    height: 100%;
    min-height: 15px;
    border-radius: 5px 0 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;
    transition: width 0.2s ease-in-out;
    font-weight: bold;
}

.centerInput.expand {
    justify-content: flex-start;
}

.centerInput.expand .codeCamp {
    width: 100%;
    border-radius: 5px 5px 0 0;
    
}


.container {
    position: relative;
    width: 100%;
}


.autoCompleteInput {

    width: 70%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 5px;
}

.autoCompleteInput:focus {
    outline: black;
    border: 3px solid black;
}

.no-suggestions {
    align-self: center;
    position: relative;
    bottom: 18px;
    color: #999;
    /* font-size: 0.9rem; */
    padding: 0;
    overflow: hidden;
    margin-top: 0.2rem;

}

.no-suggestions p {
    padding: 0 20px;

}

.containerSuggestions {
    position: absolute;
    top: 100%;
    text-align: center;
    background-color: #BEC5C3;
    border: 1px solid #999;
    border-radius: 5px;
    box-shadow: 0px 11px 20px -2px rgba(0, 0, 0, 0.5);
    list-style: none;

    border-radius: 5px;

    /*  Se cambio de 221 a 150*/
    max-height: 160px;

    z-index: 999999;
    width: 100%;
    overflow-y: scroll;
    margin: 5px;
    padding-left: 0;

    border: 2px solid black;
}

.suggestions {
    position: relative;
    font-size: 14px;
    line-height: 1.4;
    transition: 0.5;
    overflow-wrap: break-word;
}


.suggestions li {
    transition: 0.1s all;
    text-align: left;
    list-style: none;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0.5rem;
    margin: 0;
    box-sizing: border-box;
    font-weight: bold;
}

.suggestion-active,
.suggestionActive,
.suggestions li:hover {
    background-color: white;
    color: #e69108;
    cursor: pointer;
    font-weight: 700;

}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}


.autocompleteLabel {
    margin-right: 15px;
    /* 
    font-size: 15px; */
    color: rgba(0, 0, 0, 0.6);
}

.errorMsg {
    position: absolute;
    text-align: center;
    /* 
    font-size: 14px; */
    color: red;
    margin: 0 auto;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
}

/* SCROLLBAR */
/* width */
.suggestions::-webkit-scrollbar {
    width: 12px;
    border-radius: 15px;
}

/* Track */
.suggestions::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.suggestions::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.suggestions::-webkit-scrollbar-thumb:hover {
    background: #0bbe9e;
}

.error {
    border: 2px solid red;
}

.success {
    border: 2px solid green
}

.noVisible {
    display: none;
}