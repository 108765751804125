.container-group-form{
    padding: 1rem;
    border-radius: .5rem;
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    width: 26rem;
    background-color: white;
    
}

.title-form-group{
    background-color: rgb(11, 190, 190);
    color: white;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
    padding: .2rem;
}

.label-form-group{
    font-size: .9rem;
    font-weight: 600;
    margin: .5rem 0;
    color: gray;
}

.label-form-group-data{
    background-color: rgb(237, 237, 237);
    appearance: none;
    box-sizing: border-box;
    font: 1rem -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    border-style: none;
    width: 100%;
}

.count-user-picker-group{
    font-size: .9rem;
    font-weight: 600;
    margin: 1rem 0;
    color: gray;
    text-align: center;
}

.container-btn-group{
    font-size: 1.2rem;
    display: flex;
	justify-content: center;
	align-items: center;
}

.btn-form-group{
    width: 10rem; 
    color: white;
    border:none;
    background-color:rgb(248, 176, 61);
    border-radius:5px;
    text-align: center;
    padding: .5rem;
    cursor: pointer;
    margin: .5rem;
  }

.btn-form-group:hover{
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
}

.btn-cancel-form-group{
    width: 10rem; 
    color: white;
    border:none;
    background-color:rgba(167, 162, 162, 0.808);
    border-radius:5px;
    text-align: center;
    padding: .5rem;
    cursor: pointer;
    margin: .5rem;

}

.btn-cancel-form-group:hover{
    background-color:rgba(255, 22, 22, 0.808);
    box-shadow: rgba(148, 142, 142, 0.59) 3px 5px 4px 0px;
}