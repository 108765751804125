.container-gr-addreq{
    border: 2px solid rgb(11, 190, 190);
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    width: 90%;
    height: calc(100vh - 200px);
    background-color: #D0EFF5;
    padding: 20px;
    overflow: scroll;
    margin: 0 auto;
}


.container-AddReq-item-gral{
    display: flex;
    width: 100%;
    margin-top: 5px;
}

.container-AddReq-item-details{
    position: relative;
    margin-top: 20px;
    font-size: 20px;
    width: 15%;
}

.container-AddReq-item-details input[type="date"]::-webkit-calendar-picker-indicator {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 10px; /* ajusta el padding aquí según sea necesario */
    cursor: pointer;
}


.container-AddReq-item-details-applicant{
    width: 100%;
    flex: 1;
}

.container-AddReq-item-details-responsible{
    width: 100%;
    flex: 1;
}

.container-AddReq-item-details-items{
    font-size: 20px;
    width: 100%;
    height: 50%;
}

.container-AddReq-item-details-text{
    font-size: 20px;
}

.container-AddReq-item-details-text-warehouse{
    font-size: 20px;
    flex: 1;
}

.list-item-header-title-addReq{
    margin: 0 auto;
    all: unset;
    width: 100%;
}

.container-prioritys-gral{
    cursor: pointer;

}

.container-AddReq-fecha{
    background-color: white;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.label-AddReq-fecha{
    font-weight: bold;
    font-size: 1.3rem;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    border: none;
}

.container-input-texto{
    background-color: white;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
    height: 100px;
    font-size: 1.3rem;
    font-weight: bold;
}

.r-item-text-input{
    margin-left: -10px;
    margin-top: 10px;
    height: 80px;
    width: 100%;
    background-color: white;
    border: 0;

}


.container-list-products-itm-input{
    display: flex;
    margin-top: 20px;
    margin-left: 10px;

}

.r-product-input-container{
    margin-right: 30px;
}

.r-product-input-name{
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: 400px;
    height: 50px;
    font-size: 20px;
}

.r-product-input-quantity{
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: 70px;
    height: 50px;
    font-size: 20px;
}

.r-product-input-textadd{
    background-color: white;
    border: 0;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    font-size: 20px;
}

.options-container-fr{
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    right: 20px;
    padding: 10px;
    background-color: rgba(160, 160, 160, 0.486);
    border-radius: 10px;
}


.container-items-products-rq-gral{
    background-color: white;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;

}

.container-button-rq-save-new{
    display: flex;
    justify-content: center;
    width: 100%;
}

.button-rq-save-new{
    color: white;
    font-size: 30px;
    font-weight: 500;
    border:none;
    background-color:rgb(248, 176, 61);
    border-radius:5px;
    text-align: center;
    padding: 10px 12px;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    cursor: pointer;
}

.button-rq-save-new:hover{
    transform: scale(1.02);
}
.button-rq-save-new:focus{
    background-color:rgb(255, 123, 0);
}

.container-button-rq-save-new2{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}

.button-rq-save-new2{
    width: 94%;
    margin-top: 25px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    border:none;
    background-color:rgb(248, 176, 61);
    border-radius:5px;
    text-align: center;
    padding: 5px;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
}

.button-rq-save-new2-selected{
    width: 94%;
    margin-top: 25px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    border:none;
    background-color:rgb(255, 123, 0);
    border-radius:5px;
    text-align: center;
    padding: 5px;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
}

.button-rq-save-new2:hover{
    transform: scale(1.01);
}

.container-new-rq-files{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.container-new-rq-files-details{
    list-style:none;
    background-color: rgba(156, 156, 156, 0.348);
    border-radius: 10px;
    width: 90%;
    text-align: center;
}



.file-select {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .file-select::before {
    display: flex;
    position: absolute;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: 400;
    border:none;
    border-radius:5px;
    width: 100%;
  }
  
  .file-select input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 35px;
    cursor: pointer;
  }

  #src-file1::before {
    content: 'Agregar Archivo';
    cursor: pointer;
  }

  .input-files-all{
    cursor: pointer;
  }

  .label-file{
    width: 100%;
  }


  .label-aux {
    display: flex;
    background-color:rgb(255, 123, 0);
    justify-content: center;
    width: 100%;
    height: 30px;
    margin-top: 25px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    border:none;
    border-radius:5px;
    text-align: center;
    padding: 5px;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
  }


  .file-select input[type="file"]:focus + .label-aux {
    background-color:rgb(255, 123, 0);
  }

  .container-r-item-title-details{
    min-width: 500px;
    font-weight: bold;
    font-size: 22px;
    color: #7c7b7b;
  }

  .container-row-form-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-size: 16px;
  }
  
  .container-row-form-item input, 
  .container-row-form-item .input-autocomplete-form-item
  {
    padding: 10px;
    border: none;
  }