.container-list{
    padding: 6px;
    height: 85vh;
    margin-top: 70px;
  }

.home{
    display: block; /* Hidden by default */
    position: absolute;
}

html {
    overflow: hidden;
    height: 75%;
}

.opcionesHijasDiv{
    padding: 1.6rem 0 0 1rem;
    display: flex;
    gap: 0.5rem;
    position: fixed;
    z-index: 1;
    top:0;
    margin-top: 60px;
} 

.opcionHijaBtn{
    margin-left: 0.8rem;
    padding: 1rem 1.5rem;
    border-radius: 5px;
    border: 0px;
    box-shadow: 0 0 2.5px black;
    background-color: white;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
}
.opcionHijaBtn.selected{
    background-color: rgb(11, 190, 190);
    color: white;
}