.inactive{
    display: none !important;
}

.warehouseSectorListDiv{
    gap: 0.25rem;
    display: flex;
    width: 95%;
    flex-direction: column;
    padding: 1rem 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1px black;
    color: #00c1e3;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    /* Podria usar margin-left: auto o esta opcion comentada para centrarlo a la derecha
    align-items: flex-end;*/
}
.activosListDiv::-webkit-scrollbar{
    border-radius: 10px;
}
.sectorDiv{
    width: 100%;
    padding: 0.5rem 0;
}
.sectorLabel{
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}