.search-container {
  position: fixed;
  z-index: 1;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(148, 142, 142, 0.59);
  box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  transition: width 3s ease-in-out;
  font-weight: 500;
  font-family: inherit;
  top: 0;
  margin-top: 88px;
  margin-left: 38rem;
  margin-right: 85%;
  cursor: pointer;
  bottom: 85px;
}

.search-container.expanded {
  width: 50%;
}

.search-container:focus {
  outline: none;
}

.input-search-txt {
  opacity: 1;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  font: 17px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: rgb(94, 90, 90);
  font-weight: 600;
  background-color: transparent;
  width: 70%;
  border: none; 
  transition: opacity 5s;
  outline: none;
}

.add-search:hover {
  width: 50%;
  transition: width 0.7s ease-in-out;
}

.add-search:hover::before,
.add-search:hover::after {
  width: 4px;
  border-radius: 2px;
}

.add-search:hover .input-search-txt {
  opacity: 1;
}

.add-search-cont{
  font-size: 25px;
  margin-top: 12px;
  margin-left: 12px;
  color: rgba(248, 176, 61, 0.932);
}

.add-search-cont::after,
.add-search-cont::before {
  transition: all 0.2s ease-in-out;
  content: "";
  position: absolute;
  height: 20px;
  width: 2px;
  top: calc(50% - 10px);
  background:  rgba(0, 255, 255, 0.719);
  overflow: hidden;
}

.add-search-cont::before {
  left: 22px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.add-search-cont::after {
  right: 22px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.add-search:hover .add-search-cont::before {
  left: 15px;
  height: 4px;
  top: calc(50% - 2px);
}

.add-search:hover .add-search-cont::after {
  right: 15px;
  height: 4px;
  top: calc(50% - 2px);
}