.container-gr-profile{
    border: 2px solid rgb(11, 190, 190);
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    width: 420px;
    background-color: white;
}

.container-profile{
    margin-top: 5px;
}

.label-profile{
    font-size: 1.2rem;
    display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
    padding: 0 0 0 0;
	margin: 0 0;
    margin-bottom: 10px;
}

.label-profile-data{
    margin-left: 10px;
    font-weight: 600;
}

.label-profile-icon{
    font-size: 95px;
    color: rgb(11, 190, 190);
    display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
    padding: 0 0 0 0;
	margin: 0 0;
    margin-top: 30px;
    margin-bottom: 10px;
}

.btn-profile-container{
    display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
    padding: 0 0 0 0;
	margin: 0 0;
    margin-top: 15px;
    margin-bottom: 15px;
}

.btn-profile{
    width:40%; 
    color: white;
    font-size: 20px;
    border:none;
    background-color:rgb(248, 176, 61);
    border-radius:5px;
    text-align: center;
    padding: 10px 5px;
    cursor: pointer;
  }

  .btn-profile-cs{
    width:40%; 
    color: white;
    font-size: 20px;
    border:none;
    background-color:rgba(255, 22, 22, 0.808);
    border-radius:5px;
    text-align: center;
    padding: 10px 5px;
    cursor: pointer;
  }
  
  .btn-profile:hover, .btn-profile-cs:hover{
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
  }