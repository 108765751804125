.container-list{
    padding: 6px;
    height: 85vh;
  }
  
  .actualiza-responsibles-container {
      position: fixed; /* Stay in place */
      z-index: 1; /* Sit on top */
      width: 200px;
      height: 40px;
      border-radius: 15px;
      cursor: pointer;
      top: 75px;
      right: 40px;
    }
    
    .actualizar-responsibles-btn{
       border-radius: 15px;
       width: 200px;
       height: 40px;
       background-color:rgb(0, 227, 227);
       border: 1px solid rgba(148, 142, 142, 0.59);
       box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
       color: black;
       font-weight: 600;
    }
    
    .actualiza-responsibles-container:hover, .actualizar-responsibles-btn:hover{
      transform: scale(0.992);
    }