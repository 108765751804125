
.icon-btn {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    width: 50px;
    height: 50px;
    border: 1px solid rgba(148, 142, 142, 0.59);
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    background: white;
    border-radius: 25px;
    overflow: hidden;
    transition: width 0.2s ease-in-out;
    font-weight: 500;
    font-family: inherit;
    margin-left: 15px;
    margin-top: 15px;
    cursor: pointer;
  }

  .add-btn{
    position: fixed;
    right: 0 !important;
    top: 0 !important;
    margin-top: 83px !important;
    margin-right: 15.5% !important;
}
  
  .add-btn:hover {
    width: 170px;
  }
  
  .add-btn::before,
  .add-btn::after {
    transition: width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 4px;
    width: 10px;
    top: calc(50% - 2px);
    background:  rgba(0, 255, 255, 0.719);
  }
  
  .add-btn::after {
    right: 14px;
    overflow: hidden;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .add-btn::before {
    left: 14px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .icon-btn:focus {
    outline: none;
  }
  
  .btn-txt {
    opacity: 0;
    margin-left: 20px;
    margin-right: 20px;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    transition: opacity 0.2s;
  }
  
  .add-btn:hover::before,
  .add-btn:hover::after {
    width: 4px;
    border-radius: 2px;
  }
  
  .add-btn:hover .btn-txt {
    opacity: 1;
  }
  
  .add-icon::after,
  .add-icon::before {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    height: 20px;
    width: 2px;
    top: calc(50% - 10px);
    background:  rgba(0, 255, 255, 0.719);
    overflow: hidden;
  }
  
  .add-icon::before {
    left: 22px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  
  .add-icon::after {
    right: 22px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  
  .add-btn:hover .add-icon::before {
    left: 15px;
    height: 4px;
    top: calc(50% - 2px);
  }
  
  .add-btn:hover .add-icon::after {
    right: 15px;
    height: 4px;
    top: calc(50% - 2px);
  }
