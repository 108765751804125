.container-gr-form{
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    width: 700px;
    background-color: white;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.container-form{
    max-width: 100%;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}

.label-form{
    margin-bottom: 10px;
    margin-left: 15px;
    appearance: none;
    box-sizing: border-box;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    color: gray;
}

.label-form-data{
    margin-left: 20px;
    background-color: rgb(237, 237, 237);
    appearance: none;
    box-sizing: border-box;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    border-style: none;
    width: 90%;
}


.title-form{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: rgb(11, 190, 190);
    color: white;
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    border-width: 2px;
    border: 1px solid black;

}


.container-btn{
    font-size: 1.2rem;
    display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
    padding: 0 0 0 0;
	margin: 0 0;
}

.btn-form{
    width:30%; 
    color: white;
    font-size: 20px;
    border:none;
    background-color:rgb(248, 176, 61);
    border-radius:5px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 5px;
    cursor: pointer;
  }

  .btn-form:hover{
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
  }

  .btn-cancel-form{
      width:30%; 
      color: white;
      font-size: 20px;
      border:none;
      background-color:rgba(255, 22, 22, 0.808);
      border-radius:5px;
      text-align: center;
      margin-bottom: 20px;
      padding: 10px 5px;
      cursor: pointer;
    }

    .btn-cancel-form:hover{
      box-shadow: rgba(148, 142, 142, 0.59) 3px 5px 4px 0px;
    }

    .label-form-check-data{
        margin-left: 20px;
        width:17px;
        height:17px;
        
    }


    .dropdown-userTypes{
        margin-left: 20px;
        margin-bottom: 20px;
    }


    .container-checkbox{
        margin-left: 20px;
        margin-right: 50px;
    }

    .container-checkbox-userType{
        display: flex;
        background-color: rgb(237, 237, 237);
        padding: 6px;
        width: 70%;
        border-radius: 10px;
        margin-right: 10px;
        color: black;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .container-WGroup-back{
        background-color: rgb(237, 237, 237);     
        padding-bottom: 10px;   
    }

    .container-list-Wgroups{
        margin-left: 20px;
        appearance: none;
        box-sizing: border-box;
        font: 15px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        font-weight: 600;
        border-style: none;
        width: 90%;
    }
    .container-list-Wgroups-sub{
        border-bottom:solid 0.5px grey;
        width: 90%;
        margin-left: 23px;
    }

    .container-list-Wgroups-items{
        padding: 3px;
        margin-left: 7px;
    }    