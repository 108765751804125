.container-gr-filter{
    border: 2px solid rgba(248, 176, 61, 0.932);
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    /* height: 90vh; */
    max-height: 90vh;
    width: 40%;
    background-color: white;
    overflow: auto;
    display: flex;
    flex-direction: column;

}

.container-gr-filter-fil{
    padding: 10px;
}

.container-gr-subtitles{
    border-bottom:solid 1px grey;
    width: 30%;
}

.label-filter-icon-r{
    display: flex;
    justify-content: center;
    font-size: 30px;
    width: 100%;
    height: 45px;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(140, 140, 140, 0.59) 3px 3px 5px 0px;
}

.filter-icon-r{
    display: flex;
    justify-content: right;
    margin-right: 15px;
    cursor: pointer;
    width: 5%;
}

.filter-title-r{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 30px;
    color: gray;
}

.filter-container-states{
    margin-top: 10px;
}

.filter-container-states-gr{
	display: flex;
    width: 100%;
    margin-top: 10px;
    overflow: auto;
}

.container-states-f{
    display: flex;
    background-color: rgba(248, 176, 61, 0.932);
    padding: 6px;
    width: 100%;
    border-radius: 10px;
    margin-right: 10px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
}

.filter-container-priority{ 
    display: flex;
	align-items: center;
	justify-content: center;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 20px;
}

.filter-priority-item{
    width: 100%;
    display: flex;
}

.filter-priority-item-label{
    margin-left: 7px;
    display: flex;
	align-items: center;
	justify-content: center;
}


.filter-container-date{
    display: flex;
	align-items: center;
	justify-content: center;
    margin-top: 10px;
    margin-left: 20px;

}

.filter-date-item{
    width: 100%;
}

.filter-date-item-label{
    font-size: 15px;
}

.filter-container-date-h{
    display: flex;
	align-items: center;
	justify-content: left;
    margin-top: 10px;
    margin-left: 20px;

}

.container-filter-btn{
    display: flex;
	align-items: center;
	justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.filter-btn{
    width:40%; 
    color: white;
    font-size: 20px;
    border:none;
    background-color:rgb(248, 176, 61);
    border-radius:5px;
    text-align: center;
    padding: 10px 5px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 20px;
}

.filter-btn:hover{
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
  }



.label-AddFilter-fecha{
    padding: 8px;
    background-color: rgb(248, 176, 61);
    font-size: 17px;
    color: white;
    font-weight: 600;
    border-radius:5px;
}

.button-todos{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00d4e2;
    padding: 6px;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    border: none;
}