.selectList input{
    border: 2px solid black;
}

.title{
    font-weight: bold;
    font-size: 22px;
    color: #7c7b7b;
  }

.inputSelect{
    font-weight: bold;
    font-size: 18px;
}