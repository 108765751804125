.container-item-group{
    min-height: 7rem;
    display: flex;
    align-items: center;
    border-radius: .5rem;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    border: 2px solid black;
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    overflow: hidden;
    margin: .5rem 0;
}

.container-item-group:hover{
    scale: 0.99;
}

.data-item-group{
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
}

.data-item-group-column{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label-data-group{
    font-size: 1rem;
}

.info-data-group{
    font-weight: bold;
    font-size: 1.5rem;
    background-color: #48ffff;
    padding: .5rem;
    border-radius: 0.2rem;
}

.acciones-item-group{
    width: 40%;
    display: flex;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(248, 176, 61, 0.788);
    padding: 1.5rem;
}

.icon-group{
    color: white;
    font-size: 2.5rem;
    padding: 0 1rem;
    cursor: pointer;
}