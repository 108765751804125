.warehousesListDiv{
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0rem 1rem;
  align-items: center;
}

.warehouseRow{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-shadow: rgba(148, 142, 142, 0.59) 4px 2px 5px 0px;
}

.warehouseDataRow{
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
  box-shadow: rgba(148, 142, 142, 0.59) 4px 2px 5px 0px;
}

.label-title-warehouses{
  font-size: 1rem;
  font-weight: 600;
}

.warehouseSectorsRow{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  align-items: center;
  padding: 10px;
  box-shadow: rgba(148, 142, 142, 0.59) 4px 2px 5px 0px;
}

.list-item-icon-w{
  color: rgb(0, 227, 227);
  margin-right: 35px;
  margin-left: 35px;
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
}

.div-icon-warehouse-sectors{
  color: rgb(0, 227, 227);
  text-align: center;
}

.icon-warehouse-sectors{
  font-size: 40px;
  margin-top: -9px;
}