.container-applicant-list{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    /*overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;*/
    padding: 6px;
    height: 85vh;
  }

  .container-a-list{
    list-style: none; 
    padding: 5px;
    background-color: white;
}

  .container-a-list-item{
      text-shadow: 0 1px 0 rgba(255,255,255,.5);
      box-sizing: border-box;
      box-shadow: rgba(148, 142, 142, 0.59) 4px 2px 5px 0px;
  }

    .container-a-list-item:hover {
      transform: scale(0.992);
      background-color: rgba(248, 176, 61, 0.582);
    }

  .container-a-list-item-horizontal{
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(216, 216, 216); 
}

  .label-applicants{
    align-items: left;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(108, 108, 108);
    text-align: left;
    vertical-align: text-bottom;  
}

.label-title-applicants{
  padding: 10px;
  margin-right: 20px;
  align-items: left;
  font-size: 1rem;
  font-weight: 600;
  color: black;
  text-align: left;
  vertical-align: text-bottom;  
}


.list-item-icon-a{
  color: rgb(0, 227, 227);
  margin-right: 35px;
  margin-left: 35px;
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
}