.navbar-xx {
	background-color: #FFFFFF;
	position: fixed;
    bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
    border-top: 1px solid rgb(216, 216, 216); 
	width: 100%;
	height: 3.5rem;
	padding-top: 10px;
}

.navbar-container-xx{
    display: flex;
	justify-content: space-around;
	flex-grow: 1;
	overflow: hidden;
	box-shadow: 0 0 1px 0 rgba(#00c3ff, 0.25), 0 15px 30px 0 rgba(#342ead, 0.1);
}

.navbar-item-xx {

	text-decoration: none;
/* 	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;
	flex-grow: 1;
    padding: 2rem;
	color: rgb(126, 126, 126);
	outline: none;

	background: transparent;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	cursor: pointer;
	transition: all .3s; */
	color: rgb(61, 77, 135);
}

.navbar-item-xx,
.navbar-item-x{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: .5rem;
}

.navbar-item-xx:active, .navbar-item-xx:focus, .navbar-item-xx:hover{
	color: rgb(0, 55, 255);
 	outline: 0;
}

.navbar-item-xx span {
	background-color: rgb(5, 6, 45);
	padding: 16px 24px;
	border-radius: 6px;
	width: 100%;
	height: 100%;
	transition: 300ms;
}
   
.navbar-item-xx:hover span {
	background: none;
}
   
.navbar-item-xx:active {
	transform: scale(0.9);
}


.material-icons {
	display: block;
	margin-bottom: 4px;
	font-size: 1rem;
	color: mix(#fff, #4d6585, 60%);
	transition: 0.25s ease;
}

.navbar-item-label-top-xx {
	display: block;
    border-top-width: 1px;
    font-size: 25px; 
    font-style: normal; 
    font-weight: normal;
    opacity: 1;
	margin-left: 20px;
}

.navbar-item-label-bottom-xx {
	border: none;
	list-style: none;
}

.container-top-xx{
    border-bottom-color: rgb(216, 216, 216); 
    border-bottom-width: 10px; 
    box-shadow: rgb(216, 216, 216) 1px 1px 1px 1px;
    display: flex;
	flex-grow: 1;
	overflow: hidden;
    border-top: 1px solid rgb(216, 216, 216); 
    border-bottom: 1px solid rgb(216, 216, 216); 
}

.container-left-top-img-xx{
    width: 200px;
    height: 60px; 
    margin-left: 20px;
}

.icon-navbar{
	margin-bottom: 4px;
	font-size: 20px;
}

.label-screen-h2-xx{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
    padding: 0 0 0 0;
	margin: 0 0;
	color: rgb(126, 126, 126);
	text-decoration: none;
	outline: none;
	border: none;
	background: transparent;
    font-style: normal; 
    font-weight: normal;
    opacity: 1;
	
}
.navbar-item-icon-xx{
	color: black;
	margin-right: 35px;
	margin-left: 35px;
	font-size: 43px;
	font-style: normal;
	font-weight: normal;
    cursor: pointer;
}

.modal-xx{
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
	justify-content: center;
	align-items: center;
}