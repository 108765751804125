.container-user-list{
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 6px;
    height: 82vh;
  }
  
  .container-list-users{
      list-style: none; 
      padding: 8px;
      background-color: white;
  }

  .container-list-infoo{
    display: flex;
    justify-content: center;
    list-style: none; 
    padding: 5px;
    background-color: white;
    }

  .container-list-item:hover{
    transform: scale(0.992);
  }
  
  .container-list-item{
      display: flex;
      align-items: center;
      text-shadow: 0 1px 0 rgba(255,255,255,.5);
      border: 2px solid black;
      box-sizing: border-box;
      box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
      border-radius: 10px;
  }
  
  .container-list-item-infoo{
    width: 40%;
    display: flex;
    align-items: center;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    border-radius: 10px;
}

  .container-list-item-each{
      padding: 10px;
      margin-right: 20px;
      width: 100%;
  }
  
  .list-item-icon{
      color: black;
      margin-right: 35px;
      margin-left: 35px;
      font-size: 45px;
      font-style: normal;
      font-weight: normal;
  }
  
  
  .list-item{
      font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      font-weight: 600;
      font-size: 1.4rem;
      text-align: center;
  }
  
  .list-item-name{
      background-color: rgba(0, 255, 255, 0.719);
      font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      padding: 10px;
      border-radius: 5px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 1.4rem;
      text-align: center;
  }
  
  .list-item-rol{
      margin-top: 5px;
      background-color: rgba(0, 255, 255, 0.719);
      font: 10px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      padding: 6px;
      border-radius: 3px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 1.0rem;
      text-align: center;
  }
  
  .label{
      padding: 0px;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      color: gray;
      text-align: center;
      vertical-align: text-bottom;  
  }
  
  .acciones-container{
      background-color: rgba(248, 176, 61, 0.788);
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 130px;
      width: 100%;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
  }
  
  .acciones-boton{
      display: flex;
      flex-direction: row;
      align-items: center;
  }
  
  
  .icon-check-true{
      margin-left: 10px;
      margin-right: 10px;
      font-size: 30px;
      align-items: center;
      background-color: aqua;
      border: 2px solid black;
      text-align: center;
  }
  .icon-check-false{
      margin-left: 10px;
      margin-right: 10px;
      font-size: 30px;
      align-items: center;
      background-color: white;
      border: 2px solid black;
      text-align: center;
      
  }
  
  .icon{
      margin-left: 50px;
      margin-right: 50px;
      font-size: 40px;
      align-items: center;
      text-align: center;
      color: white;
      
  }

  .icon:hover{ 
    cursor: pointer;
  }

  
.modalUser{
    display: flex; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 2; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
	justify-content: center;
	align-items: center;
}

.list-item-check{
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 10px;
    align-items: center;
    width:20px;
    height:20px;
}

.cant-user{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(248, 176, 61, 0.788);
    width: 100%;
    border-radius: 10px;
}

.cant-user-cont1{
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 30px;
    margin-right: 10px;
}

.cant-user-img{
    margin-right: 10px;
    font-size: 100px;
    margin-top: -25px;
    color: white;
}
.cant-user-cont3{
    margin-top: 40px;
    font-weight: 600;
    font-size: 35px;
}

.cant-user-img2{
    margin-right: 10px;
    font-size: 46px;
    margin-left: 10px;
    margin-top: 27px;
    color: white;
}

.cant-user-items{
    display: flex;
    justify-content: left;
    width: 100%;
    font-weight: 600;
    padding-top: 5px;
    font-size: 17px;
}

.cant-user-number{
    display: flex;
    justify-content: right;
    width: 100%;
    font-weight: 600;
    margin-left: 30px;
}

.divQRs{
    display:flex;
    flex-direction:column;
    flex:0.4;
    padding-left: 2rem;
    align-items: center;
}
.containerQRs{
    display:flex; 
    gap:2rem; 
    width:100%;
    justify-content: center;
}
.qrLinkAppDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.qrLinkAppDiv h5{
    font-weight: 500;
}